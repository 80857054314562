.hrzone h1 {
  margin: 20px 10px;
}
.hrzone h2 {
  margin: 20px 20px;
}
.hrzone h3 {
  margin: 20px 30px;
}
.hrzone h4 {
  margin: 20px 40px;
}
.hrzone p {
  text-align: left;
  margin: 20px 50px;
}
.hrzone table {
  width: 95%;
  margin: 20px auto;
}
.hrzone img {
  width: 750px;
  margin: 10px auto;
}
.hrzone ul {
  text-align: left;
  margin-left: 100px;
}
