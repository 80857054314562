.analyze_condition_form {
  text-align: left;
  background-color: lightgray;
  border-radius: 5px;
  padding: 25px;
  width: 500px;
  margin: 30px auto;
}
.analyze_condition_form label {
  color: dimgray;
  font-weight: bold;
}
.analyze_condition_form input {
  margin-bottom: 10px;
}
