.gps_file_selection_form {
  background-color: lightgray;
  text-align: left;
  border-radius: 5px;
  padding: 25px;
  width: 500px;
  margin: 30px auto;
}
.gps_file_selection_form label {
  color: dimgray;
  font-weight: bold;
}
.gps_file_selection_form input {
  margin-bottom: 10px;
}
.gps_file_selection_form .no_display {
  display: none;
}
