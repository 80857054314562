.home {
  padding: 10px;
  margin: 10px;
}
.home .primary {
  text-align: left;
  padding: 10px;
  width: 80%;
  margin: 10px;
}
.home .side_bar {
  border: 1px solid dimgray;
  border-radius: 5px;
  width: 20%;
  margin: 5px;
}
.home p {
  text-align: left;
}
.home .side_bar li {
  list-style: none;
}
.home .default_page_title {
  text-align: center;
  border-top: 5px solid dimgray;
  border-bottom: 5px solid dimgray;
}
