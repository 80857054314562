.analyze_button_div {
  text-align: center;
  background-color: lightgray;
  border-radius: 5px;

  width: 500px;
  margin: 30px auto;
}
.analyze_button_div button {
  margin: 10px;
}
.analysis h1 {
  margin: 20px 10px;
}
.analysis h2 {
  margin: 20px 20px;
}
.analysis h3 {
  margin: 20px 30px;
}
.analysis h4 {
  margin: 20px 40px;
}
.analysis p {
  text-align: left;
  margin: 20px 50px;
}
.analysis table {
  width: 95%;
  margin: 20px auto;
}
.analysis img {
  width: 750px;
  margin: 10px auto;
}
